.testimonials {
    display: flex;
    $self: &;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }

    &__image-container__title,
    &__content__title {
        margin-bottom: 40px;

        svg {
            height: 0.75em;
            vertical-align: baseline;
            min-width: 0;
            width: auto;
            margin-left: 0.3em;
        }
    }

    &__image-container {
        margin-bottom: 40px;
        @media (min-width: 768px) {
            width: 30%;
            margin-right: 8%;
            margin-bottom: 0;

            &__title {
                display: none;
            }
        }
    }
    &__content {
        // max-width: 750px;
        flex: 1;
        margin: 0 auto;
        @media (max-width: 767px) {
            width: 100%;
        }

        &--only-quotes {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__provider-rating {
            margin-bottom: 1.5em;
        }
    }

    &__progress-bar {
        width: calc(2 / 3) * 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 32px;

        @media (min-width: 1024px) {
            width: calc(100% + 0.5em);
            margin-left: -0.25em;
        }

        > div {
            width: 6px;
            height: 6px;

            &.active {
                width: 36px;
            }
        }
        > :global(div.active) {
            width: 36px;
        }
    }

    &__container {
        width: 100%;
        @media (max-width: 767px) {
            margin-left: -16px;
            margin-right: -16px;
            padding: 0 16px;
            overflow: hidden;
        }
        &__rolling-text {
            display: flex;
            white-space: normal;
            height: 300px;
            @media (max-width: 767px) {
                height: 360px;
            }
        }
    }

    &__review {
        max-width: 784px;

        &__byline {
            display: flex;
            align-items: center;
            height: 48px;
            color: var(--gray-90);
            margin-top: 16px;

            &__image {
                display: inline-block;
                border-radius: 100%;
                overflow: hidden;
                height: 48px;
                width: 48px;
                margin-right: 16px;

                :global(> div) {
                    height: 100%;
                }
            }
        }
    }

    &__ratings-outer {
        &--scroll {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            margin: 20px auto;
            max-width: 1100px;

            #{$self}__ratings {
                width: max-content;
                margin: 0;
                list-style-type: none;
                max-width: none;
                --gap: 120px;
                @media (max-width: 767px) {
                    --gap: 50px;
                }
                padding-right: var(--gap);
                animation: scroll 15s linear infinite;
                will-change: transform;
                backface-visibility: hidden;
                transform: translate3d(0, 0, 0);
                }
            #{$self}__image__item img {
                aspect-ratio: 3;
            }
        }
    }

    &__ratings {
        display: inline-block;
        max-width: 850px;
        justify-content: center;
        margin: 56px auto;
        --gap: 56px;

        @media (max-width: 767px) {
            justify-content: left;
            --gap: 24px;
            margin: 0 20px;
            width: 100%;
            overflow: hidden;
        }

        &__item {
            display: inline-flex;
            max-width: 500px;
            margin-right: var(--gap);

            &:last-child {
                margin-right: 0px;
            }
            @media (max-width: 767px) {
                max-width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;
                // margin-bottom: var(--gap);
            }



            &__icon {
                width: 4em;
                height: 4em;
            }
            &__rating,
            h3 {
                font-weight: 500;
                margin-left: 1em;
            }
        }
    }
    &__image__item img {
        max-width: 100%;
        width: auto;
        height: 3em;
    }
}

@keyframes scroll {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}
