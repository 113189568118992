.star-rating {
    display: flex;
    flex-direction: row;
    position:relative;

    &__stars{
        width: 5.625em;
        position:relative;
        &--filled{
            white-space: nowrap;
            position:absolute;
            left: 0;
            top:0;
            overflow:hidden;
        }
    }

    &__number {
        margin-left: 0.5em;
        font-weight: 500;
    }
    &__star {
        height: 1em;
        width: 1em;
        margin: 0 1px;

        &--full {
            fill: currentColor;
            stroke: none;
            stroke-width: 0px;
        }
        &--empty {
            fill: var(--gray-60);
            stroke: none;
            stroke-width: 0px;
        }
    }
}
